@import 'mixins';
@import 'variables';
@import 'polices';
@import 'accessibilite';

body {
    font-family: $montserrat;
    color: $bleu-marine;

    &.noscroll {
	    height: 100%;
	    overflow: hidden;
    }
}

#chargement {
	display: none !important;
}

#secondaire {
	opacity: 1 !important;
	transition: opacity 0.25s;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    min-height: 100vh;
    background: $blanc;
}

#sentinelle {
    position: absolute;
    top: 0; left: 0;
    width: 100%;

    @media only screen and (max-width: $breakpoint-menu) {
        height: 1px;
    }

    @media only screen and (min-width: ($breakpoint-menu + 1px)) {
        height: 16px;
    }
}

@import 'vendor/slick';
@import 'vendor/fancybox';
@import 'structure';

.texte-blanc { color: $blanc; }

.fond-blanc { background: $blanc; }
.fond-bleu-marine { background: $bleu-marine; }
.fond-bleu { background: $bu-bleu; }
.fond-gris { background: $bu-gris; }
.fond-marron { background: $bu-marron; }
.fond-jaune { background: $bu-jaune; }
.fond-vert { background: $bu-vert; }
.fond-rose { background: $bu-rose; }

.no-border {border: none !important;}

.fond-gris-clair { background: $gris-clair; }
.fond-gris-fonce { background: $gris-fonce; }

.fond-standard { background: $bleu-marine; }
body.main-bu-bleu .fond-standard, body.main-bu-bleu footer, body.main-bu-bleu .bloc-texte-et-media .media::before { background: $bu-bleu; }
body.main-bu-gris .fond-standard, body.main-bu-gris footer, body.main-bu-gris .bloc-texte-et-media .media::before  { background: $bu-gris; }
body.main-bu-marron .fond-standard, body.main-bu-marron footer, body.main-bu-marron .bloc-texte-et-media .media::before { background: $bu-marron; }
body.main-bu-jaune .fond-standard, body.main-bu-jaune footer, body.main-bu-jaune .bloc-texte-et-media .media::before { background: $bu-jaune; }
body.main-bu-vert .fond-standard,  body.main-bu-vert footer, body.main-bu-vert .bloc-texte-et-media .media::before { background: $bu-vert; }
body.main-bu-rose .fond-standard, body.main-bu-rose footer, body.main-bu-rose .bloc-texte-et-media .media::before { background: $bu-rose; }


.couleur-standard { color: $bleu-marine; }
body.main-bu-bleu .couleur-standard { color: $bu-bleu; }
body.main-bu-gris .couleur-standard { color: $bu-gris; }
body.main-bu-marron .couleur-standard { color: $bu-marron; }
body.main-bu-jaune .couleur-standard { color: $bu-jaune; }
body.main-bu-vert .couleur-standard { color: $bu-vert; }
body.main-bu-rose .couleur-standard { color: $bu-rose; }

.survol-bleu:is(:hover,.actif) { color: $bu-bleu; }
.survol-gris:is(:hover,.actif) { color: $bu-gris; }
.survol-marron:is(:hover,.actif) { color: $bu-marron; }
.survol-jaune:is(:hover,.actif) { color: $bu-jaune; }
.survol-vert:is(:hover,.actif) { color: $bu-vert; }
.survol-rose:is(:hover,.actif) { color: $bu-rose; }

@media only screen and (max-width: $breakpoint-menu) {
    .survol-bleu { color: $bu-bleu; }
    .survol-gris { color: $bu-gris; }
    .survol-marron { color: $bu-marron; }
    .survol-jaune { color: $bu-jaune; }
    .survol-vert { color: $bu-vert; }
    .survol-rose { color: $bu-rose; }
}

.une-ligne { @include generer-line-clamp(1); }
.deux-lignes { @include generer-line-clamp(2); }
.quatre-lignes { @include generer-line-clamp(4); }


.float_round {
    position: absolute;
    z-index: 100;
    will-change: transform; // Pour optimiser les changements de position

    &:nth-child(1) {top: 33%}
    &:nth-child(2) {top: 66%}
    &:nth-child(3) {top: 100%}

    &--right {
        left: 0;
    }

    &--left {
        right: 0;
    }
}

.buCircle {
    position: relative;

    &__bg {
        position: absolute;
        @media screen and (max-width: 841px) {
            &--desktop {
                display: none;
            }
            &--mobile {
                left: 0;
            }
        }
        @media screen and (min-width: 841px) {
            &--mobile {
                display: none;
            }
            &--desktop {
                left: 50%;
                transform: translateX(-50%);
                svg {
                    width: 100%;
                    display: block;
                }
            }
        }
    }

    &__choices {

        height: 401px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 17px;
        padding-left: 20px;

        &__unit {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;

            &:hover {
                &:nth-child(1), &:nth-child(2), &:nth-child(4) {.label {transform: translateX(-10px)}}
                &:nth-child(3), &:nth-child(5), &:nth-child(6) {.label {transform: translateX(10px)}}
            }

            @media screen and (max-width: 841px) {
                gap: 10px;

                &:nth-child(2), &:nth-child(5) {left: 45px;}
                &:nth-child(3), &:nth-child(4) {left: 70px;}
            }

            @media screen and (min-width: 841px) {
                position: absolute;
                gap: 15px;
                left: 50%;
                &:nth-child(1) { transform: translateX(-350px);top: 20px; svg {order: 2;}}
                &:nth-child(2) { transform: translateX(-340px); bottom: 20px; svg {order: 2;} }
                &:nth-child(3) { transform: translateX(105px); bottom: 20px }
                &:nth-child(4) {transform: translateX(-330px);svg {order: 2;}}
                &:nth-child(5) {transform: translateX(175px)}
                &:nth-child(6) {transform: translateX(100px);top: 20px;}
            }

            &.bu-bleu { svg * { fill: $bu-bleu; } .label {color: $bu-bleu} }
            &.bu-gris { svg * { fill: $bu-gris; } .label {color: $bu-gris} }
            &.bu-marron { svg * { fill: $bu-marron; } .label {color: $bu-marron} }
            &.bu-jaune { svg * { fill: $bu-jaune; } .label {color: $bu-jaune} }
            &.bu-vert { svg * { fill: $bu-vert; } .label {color: $bu-vert} }
            &.bu-rose { svg * { fill: $bu-rose; } .label {color: $bu-rose} }

            &__label {
                font-size: 1.5rem;
                transition: .3s;

                @media screen and (min-width: 841px) {
                    font-size: 1.8rem;
                }
            }

            svg {
                width: 46px;
                height: 46px;
                background-color: #fff;
                border-radius: 20px;

                @media screen and (min-width: 841px) {
                    width: 52px;
                    height: 52px;
                }
            }
        }
    }
}

.bouton-bleu-marine {
    @include generer-bouton();
    background: $bleu-marine;
    color: $blanc;
    border: 1px solid $bleu-marine;
    transition: 0.25s background, 0.25s color;
    padding: 10px 30px;

    &--gros {
        @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
        font-weight: 600;
    }

    &:hover {
        background: $blanc;
        color: $bleu-marine;
    }
}

.gros-bouton-blanc {
    @include generer-bouton();
    background: $blanc;
    color: $bleu-marine;
    border: 1px solid $bleu-marine;
    transition: 0.25s background, 0.25s color;
    padding: 10px 30px;
    font-weight: 600;
    @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);

    &.avec-picto {
        display: inline-flex;
        align-items: center;
        padding-right: 15px;
        gap: 10px;
    }

    &:hover {
        background: $bleu-marine;
        color: $blanc;
    }
}

.bouton-contact {
    @include generer-bouton();
}

.avec-hover-gras {
    .hover-gras {
        display: inline-block;
        position: relative;

        .invisible {
            @media only screen and (max-width: $breakpoint-menu) {
                display: none;
            }
            @media only screen and (min-width: ($breakpoint-menu + 1px)) {
                display: inline-block;
                visibility: hidden;
                font-weight: 600;
                user-select: none;
                pointer-events: none;
            }
        }

        .visible {
            position: absolute;
            top: 10px; left: 50%;
            transform: translate(-50%, 0);
            font-weight: 400;
            transition: 0.125s font-weight;
        }
    }

    &:is(:hover, :focus-within) .visible {
        font-weight: 600;
    }
}

#checkbox-menu {
    position: fixed;
    top: 0; left: 0;
    height: 1px; opacity: 0;
}

#header {
    position: absolute;
    width: 100%;
    z-index: 10;
    color: $bleu-marine;

    .burger {
        span {
            background: $bleu-marine !important;
        }
    }

    &.no-home {
        .burger {
            span {
                background: #fff !important;
            }
        }

        .menu-deroulant-langues {
            button {
                .visible {
                    color: #fff;
                }
                &::after {
                    color: #fff;
                }
            }
        }

        body.scrolled & {

            .burger {
                span {
                    background: $bleu-marine !important;
                }
            }

            .menu-deroulant-langues {
                button {
                    .visible {
                        color: $bleu-marine;
                    }
                    &::after {
                        color: $bleu-marine;
                    }
                }
            }

            #logo {
                * {
                    fill: $bleu-marine !important;
                }
            }
            @media screen and (min-width: $breakpoint-menu) {
                .menu-item-li {
                    color: $bleu-marine;
                }
            }
        }
        #logo {
            * {
                fill: #fff !important;
            }

            body:is(.scrolled) {
                #logo {
                    * {
                        fill: $bleu-marine !important;
                    }
                }
            }
        }
    }

    body:not(.scrolled) & {
        // background: #{$blanc}00;
        // background: $blanc;
        background: rgba(255, 255, 255, 0);
    }

    // illisible
    body.menu-transparent & {
        background: rgba(255, 255, 255, 0);
        color: $blanc;
    }

    body.scrolled & {
        position: fixed;
        top: 0;
        background: rgba(255, 255, 255, 0.95);
        color: $bleu-marine;
        transition: background 0.25s;
    }

    @media screen and (max-width: 540px) {
        #checkbox-menu:checked ~ & {
            * {
                fill: #fff !important;
            }
        }
    }

    @media only screen and (max-width: $breakpoint-menu) {
        top: 0;

        [class*="contenu-"] {
            position: relative;
            z-index: 12;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        body:is(.home) & {
            #logo {
                * {
                    fill: $bleu-marine;
                }
            }
        }

        #logo {
            position: relative;
            top: 4px;
            z-index: 140;
            width: 72px;
        }

        #sous-burger {
            position: fixed;
            top: 0;
            right: -100vw;
            width: 100vw;
            height: 70px;
            background: $bleu-marine;
            z-index: 125;
            transform: translateX(0%);
            transition: transform 0.15s;

            #checkbox-menu:checked ~ & {
                transform: translateX(-100%);
                transition: transform 0.25s;
            }
        }

        #burger {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            position: relative;
            z-index: 140;
            right: -11px;

            &:hover {
                cursor: pointer;
            }

            span.burger {
                position: relative;
                display: block;
                width: 36px;
                height: 36px;
                transform: rotate(0deg);
                transition: .5s ease-in-out;

                span {
                    display: block;
                    position: absolute;
                    height: 2px;
                    width: 22px;
                    border-radius: 4px;
                    opacity: 1;
                    left: calc((36px - 22px) / 2);
                    transform: rotate(0deg);
                    transition: transform .25s ease-in-out, top .25s ease-in-out;
                    // background: #fff;
                    background: #fff;

                    // body.scrolled & {
                    //     background: $bleu-marine;
                    // }
                }

                span:nth-child(1) { top: 11px; }
                span:nth-child(2) { top: 18px; }
                span:nth-child(3) { top: 18px; }
                span:nth-child(4) { top: 25px; }

                #checkbox-menu:checked ~ & {
                    span {
                        background: #fff !important;
                    }

                    span:nth-child(1) {
                        top: 24px;
                        width: 0%;
                        left: 50%;
                    }

                    span:nth-child(2) {
                        transform: rotate(45deg);
                    }

                    span:nth-child(3) {
                        transform: rotate(-45deg);
                    }

                    span:nth-child(4) {
                        top: 24px;
                        width: 0%;
                        left: 50%;
                    }
                }
            }

            .ouvrir {
                display: inline-block;
                line-height: 14px;

                svg {
                    margin-top: 1px;
                }
            }

            .fermer {
                display: none;
                line-height: 10px;
            }

            #checkbox-menu:checked ~ & {
                .ouvrir {
                    display: none;
                }

                .fermer {
                    display: inline-block;
                }
            }
        }

        #overlay {
            #checkbox-menu:checked ~ & {
                position: fixed;
                top: 0; left: 0;
                height: 100%; width: 100%;
                z-index: 11;
                content: ' ';
            }
        }

        nav {
            position: fixed;
            top: 70px;
            right: -100vw;
            width: 100vw;
            height: calc(100vh - 70px);
            background: transparent;
            z-index: 120;
            text-align: left;
            overflow-y: scroll;
            overflow-x: hidden;
            color: $blanc;
            visibility: hidden;
            transition: transform 0.15s, visibility 0.01s 0.15s;
            font: 400 18px/22px $montserrat;
            display: flex;
            flex-direction: column;

            #checkbox-menu:checked ~ & {
                transform: translateX(-100%);
                transition: transform 0.25s;
                visibility: visible;
            }

            ul.arborescence {
                #checkbox-menu:checked ~ & {
                    display: block;
                    width: 100%;
                    background: $bleu-marine;
                    padding: 0 23px;

                    li {
                        width: 100%;
                        background: $bleu-marine;

                        & > :is(a, button) {
                            display: block;
                            width: 100%;
                            text-align: left;
                            padding: 10px 5px;

                            .visible {
                                position: static;
                            }
                        }

                        button {
                            position: relative;
                            padding-right: 32px;

                            &:after {
                                position: absolute;
                                top: 50%; right: 0;
                                font: normal 16px/1em $fontello;
                                content: '\e803';
                                transform: translateY(-50%);
                                color: $blanc;
                            }
                        }

                        &[class*="menu-deroulant-"] {
                            & > ul {
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100%;
                                z-index: 130;
                                height: calc(100vh - 70px);
                                overflow: scroll;
                                background: $bleu-marine;
                                color: $blanc;
                                transform: translateX(100%);
                                transition: transform 0.25s;
                                padding-bottom: 140px;

                                a:hover {
                                    font-weight: 600;
                                    transition: 0.125s font-weight;
                                }

                                li {
                                    display: none;
                                    padding: 0 23px;

                                    & > * {
                                        display: block;
                                        padding: 10px 5px;
                                    }

                                    ul {
                                        display: block;
                                        padding: 0;
                                        font-size: 16px;
                                    }
                                }

                                li.retour {
                                    padding-bottom: 20px;

                                    button {
                                        &:after {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            button[aria-expanded="true"] {
                                & + ul {
                                    transform: translateX(0px);
                                    transition: transform 0.25s;

                                    li {
                                        display: block;
                                    }
                                }
                            }

                            :is(.svg, .illustration) {
                                display: none;
                            }
                        }
                    }
                }
            }

            .spacer {
                flex: 1;
                content: ' ';
                background: $bleu-marine;
            }

            .cta {
                display: block;
                position: relative;
                width: 100%;
                padding: 70px 23px 0;
                background: $bleu-marine;
                overflow: hidden;

                .bouton-contact {
                    border: 1px solid $blanc;
                    color: $bleu-marine;
                    background: $blanc;
                    transition: 0.25s font-weight;

                    &:hover {
                        font-weight: 600;
                    }
                }

                &.masquer {
                    display: none;
                }
            }

            .menu-deroulant-langues {
                background: $bleu-marine;
                width: 100%;
                // padding: 23px 23px 45px;
                padding: 23px;
                font-size: 12px;

                button, .mini {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 580px) {
        #overlay {
            display: none;
        }

        #logo {
            svg {
                body:is(.scrolled, .menu-transparent):not(.noscroll) & {
                    * {
                        fill: $bleu-marine;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 581px) and (max-width: $breakpoint-menu) {
        #logo {
            svg {
                body.scrolled & {
                    * {
                        fill: $bleu-marine;
                    }
                }
            }
        }

        #sous-burger {
            right: -380px;
            width: 380px;
        }

        nav {
            right: -380px;
            width: 380px;

            ul.arborescence {
                #checkbox-menu:checked ~ & {
                    li {
                        &.menu-deroulant-1-niveau {
                            ul {
                                transform: translateX(380px);
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 640px) {
        [class*="contenu-"] {
            padding: 0 15px;
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    @media only screen and (min-width: 641px) and (max-width: $breakpoint-menu) {
        [class*="contenu-"] {
            padding: 0 20px;
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    @media only screen and (min-width: 841px) and (max-width: $breakpoint-menu) {
        [class*="contenu-"] {
            height: 70px;
        }

        #logo {
            width: 90px;
        }
    }

    @media only screen and (min-width: ($breakpoint-menu + 1px)) {
        top: 16px;

        [class*="contenu-"] {
            position: relative;
            z-index: 12;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            padding-top: 8px;
            padding-bottom: $decalage-bas-menu;
        }

        #logo {
            display: block;
            width: 133px;
            height: 67px;


            svg {
                width: 100%;

                body.menu-transparent & {
                    * {
                        fill: $blanc;
                    }
                }

                body:is(.scrolled, .menu-normal) & {
                    * {
                        fill: $bleu-marine;
                    }
                }
            }
        }

        nav {
            // flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 32px;

            ul.arborescence {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                & > li {
                    position: relative;
                    font: 400 20px/20px $montserrat;

                    & > * {
                        position: relative;
                        font: inherit;
                        line-height: 20px;
                        color: inherit;
                        padding: 10px 16px;
                        white-space: nowrap;
                    }

                    & > :is(a, button) {
                        &:hover {
                            body:is(.menu-normal.scrolled) & {
                                color: $bleu-marine;
                            }
                        }
                    }

                    & > button {
                        &[aria-expanded="true"] {
                            & + ul {
                                visibility: visible;
                                opacity: 1;
                                transform: translate(-50%, 0px);
                                transition: visibility 0s, opacity 0.5s 0.001s, transform 0.25s 0.001s;
                            }
                        }
                    }

                    & > ul {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        visibility: hidden;
                        position: absolute;
                        top: calc(100% + $decalage-bas-menu); left: 50%;
                        color: $bleu-marine;
                        opacity: 0;
                        width: 280px;
                        border-radius: 25px;
                        transform: translate(-50%, 20px);
                        background: $fond-blanc-transparent;
                        padding: 20px 25px;

                        &:before {
                            position: absolute;
                            top: -#{$decalage-bas-menu}; left: 0;
                            width: 100%; height: $decalage-bas-menu;
                            content: ' ';
                        }

                        li.retour {
                            display: none;
                        }

                        a {
                            display: block;
                            font: 400 20px/27px $montserrat;
                            text-align: left;
                            padding: 5px;

                            &:hover {
                                font-weight: 600;
                            }
                        }
                    }

                    // &.menu-deroulant-1-niveau {
                    // }

                    &.menu-deroulant-2-niveaux {
                        & > ul {
                            display: block;
                            width: ((3 * 200px) + 3 * 30px);
                            min-height: 260px;
                            padding: 30px 480px 30px 25px;

                            &:after {
                                position: absolute;
                                top: 30px; bottom: 30px;
                                right: 460px;
                                width: 1px;
                                background: rgba(68, 68, 68, 0.5);
                                content: ' ';
                            }

                            .mobile {
                                display: none;
                            }

                            .section {
                                text-align: left;

                                & > a {
                                    display: inline-block;
                                    padding: 4px 10px;
                                }

                                :is(.svg,.illustration) {
                                    position: absolute;
                                    top: 30px;
                                    right: 30px;
                                    width: 200px;
                                    display: flex;
                                    opacity: 0;
                                    transition: 0.25s opacity;
                                }

                                .svg {
                                    height: 200px;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: 38%;

                                    img {
                                        width: 100px;
                                    }
                                }

                                ul {
                                    position: absolute;
                                    top: 30px; bottom: 30px;
                                    right: 240px;
                                    width: 210px;
                                    height: calc(100% - 2 * 30px);
                                    max-height: calc(100% - 2 * 30px);
                                    overflow-y: auto;
                                    display: block;
                                    padding: 0 10px;
                                    opacity: 0;
                                    transition: 0.25s opacity;
                                    z-index: -1;

                                    a {
                                        display: block;
                                        padding: 8px 0;
                                        font: 400 14px/1.4em $montserrat;
                                        white-space: break-spaces;

                                        &:hover {
                                            font-weight: 600;
                                        }
                                    }
                                }

                                & > a.actif {
                                    font-weight: 600;

                                    & ~ * {
                                        opacity: 1;
                                        z-index: 1;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cta {

                .bouton-contact {
                    border: 1px solid $bleu-marine;
                    font: 20px/20px $montserrat;
                    background: $bleu-marine;
                    color: $blanc;
                    transition: 0.25s color, 0.25s background, 0.25s border-color;

                    body:not(.scrolled, .home) & {
                        background: #fff;
                        color: $bleu-marine;
                        border: 1px solid #fff;

                        &:hover {
                            color: #fff;
                            background: $bleu-marine;
                        }
                    }

                    &:hover {
                        color: $bleu-marine;
                        background: $blanc;
                    }
                }

                body.menu-transparent:not(.scrolled) & .bouton-contact {
                    background: $blanc;
                    border-color: $blanc;
                    color: $bleu-marine;

                    &:hover {
                        background: $bleu-marine;
                        border-color: $bleu-marine;
                        color: $blanc;
                    }
                }
            }

            .menu-deroulant-langues {
                position: relative;
                text-transform: uppercase;

                button {
                    padding: 10px 12px;
                    text-transform: uppercase;
                    font: 400 20px/20px $montserrat;

                    &:after {
                        position: absolute;
                        top: 13px; right: 0;
                        font: 10px $fontello;
                        content: '\e800';
                        transform: rotate(0deg);
                        transition: 0.25s transform;
                    }

                    &[aria-expanded="true"] {
                        & + ul {
                            visibility: visible;
                            opacity: 1;
                            transform: translate(-50%, 0px);
                            transition: visibility 0s, opacity 0.5s 0.001s, transform 0.25s 0.001s;
                        }
                    }
                }

                & > ul {
                    display: block;
                    flex-direction: column;
                    gap: 10px;
                    visibility: hidden;
                    position: absolute;
                    top: calc(100% + $decalage-bas-menu); left: 50%;
                    color: $bleu-marine;
                    opacity: 0;
                    width: 80px;
                    transform: translate(-50%, 20px);
                    background: $fond-blanc-transparent;
                    padding: 0;

                    &:before {
                        position: absolute;
                        top: -#{$decalage-bas-menu}; left: 0;
                        width: 100%; height: $decalage-bas-menu;
                        content: ' ';
                    }

                    a {
                        display: block;
                        text-align: center;
                        padding: 16px;

                        .complet {
                            display: none;
                        }

                        &:hover {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        #burger,
        #sous-burger,
        #label-burger,
        .spacer {
            display: none;
        }
    }
}

#checkbox-menu {
    @media only screen and (min-width: ($breakpoint-menu + 1px)) {
        display: none;
    }
}


#main {
    flex: 1;
    position: relative;
    z-index: 2;
    overflow: hidden;

}

@import 'titres';

.texte-gauche { text-align: left; }
.texte-droite { text-align: right; }
.texte-centre { text-align: center; }

.radius-top-right {
    border-radius: 0 50px 0 0;
}

.avec-image-de-fond {

    position: relative;
    overflow: hidden;

    .image-de-fond {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        z-index: 1;

        img {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            object-fit: cover;
            z-index: 1;
            filter: grayscale(0.5);
        }

        video {
            width: 100%;
            object-fit: cover;
            height: 100%;
        }

        .overlay {
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            z-index: 12;
            opacity: 0.94;
        }
    }

    &.radius-top-right {
        .image-de-fond {
            border-radius: 0 50px 0 0;
            overflow: hidden;
        }
    }

    .contenu-large {
        position: relative;
        z-index: 2;
    }
}

.hero {
    &.avec-image-de-fond {
        overflow: initial;
        @media screen and (min-width: 841px) {
            min-height: calc( 90vh - var(--decalage-header) - clamp(40px,40px + (80 - 40)*(100vw - 480px)/(1120 - 480),80px) );
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }
        .image-de-fond {
            .overlay {
                border-radius: 0 0 50px 0;
            }
        }
    }
    &.bg-gray {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $gris-clair;
            z-index: -1;
        }
    }
    &:not(.hero-accueil):before {
        display: block;
        content: ' ';
        height: var(--decalage-header);
    }

    &__illustration {
        flex: 1;
        max-width: calc(100% - 2* var(--padding-lateral));
        margin: 0 auto;

        @media screen and (max-width: 1001px) {
            padding-top: 30px;
        }

    }
}

.hero-accueil {
    margin-top: var(--decalage-header);
    // min-height: 300px;
    // display: flex;
    // align-items: center;

    .image-de-fond {
        &::before {
            z-index: 2;
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $bleu-marine;
            opacity: .8;
        }
    }

    .overlay {
        background: rgba(0, 26, 60, 0.53);
    }
}

.hero-bu {
    border-radius: 0 0 50px 0;

    @media screen and (min-width: 1001px) {
        .desktop-flex-it {
            display: flex;
            gap: 40px;

            .left-part {
                max-width: 550px !important;
            }
        }
    }

    @media screen and (min-width: 841px) {
        padding-bottom: 70px;
    }

    picture {
        position: relative;
        z-index: 2;

        img {
            @media screen and (max-width: 841px) {
                max-width: calc(100% - 2* var(--padding-lateral));
                margin: 40px auto 20px auto;
            }
            @media screen and (min-width: 841px) and (max-width: 1001px) {
                max-width: 500px;
                margin: 40px 0 0;
            }
            @media screen and (min-width: 1001px) {

            }

        }
    }

    .overlay {
        opacity: 0.94;
    }

    h1 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;

        @media only screen and (min-width: 481px) {
            flex-direction: row;
            align-items: center;
            gap: 30px;
        }

        svg {
            flex-shrink: 0;
            transform: translateY(10%);
            @include generer-responsive('width', $grande-largeur, 98px, $largeur-mini, 49px);
        }
    }
}

#breadcrumbs {
    font-weight: 400;
    line-height: 1.3em;
    @include generer-responsive('font-size', $grande-largeur, 14px, $largeur-mini, 12px);

    .breadcrumb_last {
        font-weight: 600;
    }
}

.hero-simple {
    border-radius: 0 0 50px 0;

    @media screen and (min-width: 841px) {
        padding-bottom: 70px;
    }

    h1 {
        color: $blanc;
        font-weight: 600;
        line-height: 1.35em;

        span {
            position: relative;
            left: -3px;
            display: inline;
            background: $bleu-marine;
            box-decoration-break: clone;

            @media screen and (min-width: 481px) {
                left: -4px;
            }
            @media screen and (min-width: 841px) {
                left: -7px;
            }
        }
    }

    .introduction {
        &-formation {
            @media screen and (min-width: 1001px) {
                max-width: 500px;
            }
            @media screen and (min-width: 1401px) {
                max-width: 750px;
            }
        }
    }

    .proprietes {

        @media screen and (min-width: 841px) {

            * {
                text-align: left;
            }
        }
        &--flex-start {
            @media screen and (min-width: 1001px) {
                align-items: flex-start;
            }
        }
        &--formation {
            margin-top: 35px;
            .tags {
                a {
                    color: $bleu-marine;
                }
            }
        }
        .inscription {
            .bouton-bleu-marine {
                font-weight: 600;
                @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
            }
        }

        .tags {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            a {
                display: inline-block;
                border-radius: 15px;
                padding: 10px 20px 10px 18px; // l'italic rompt la symétrie
                font-style: italic;
                font-weight: 500;
                transition: .25s all;

                &:hover {
                    background: $gris-fonce;
                    color: $blanc;
                }
            }
        }

        .encart {
            border-radius: 25px;
            flex-shrink: 0;
            border: 1px solid $bleu-marine;
            width: 320px;
            max-width: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            gap: 15px;

            &--gap25 {
                gap: 25px;
            }

            &--center {
                * {
                    text-align: center;
                }
            }

            @media screen and (min-width: 1001px) {
                transform: translateY(-130px);
                margin-bottom: -130px;
                padding: 50px 20px;
            }

            & > * {
                display: block;
            }

            & > * + * {
                margin-top: 0;
            }

            img {
                width: 80%;
                margin: 10px auto;
                filter: grayscale(1);

                &:hover {
                    filter: grayscale(0);
                }
            }

            :is(.client, .duree) {
                font-weight: 600;
            }

            a:hover {
                text-decoration: underline;
            }

            .picto {
                position: relative;
                padding-left: 40px;

                i {
                    position: absolute;
                    top: 0; left: 0;
                    // transform: translateY(-50%);
                }
            }
        }
    }
}

.wysiwyg {
    @import 'wysiwyg';
}

@import 'vendor/simpletextrotator';

.bloc-vision {
    &.fond-gris-clair {
        background-color: #BFC3C680;
    }
}

.bloc-business-units-mosaique {
    @media only screen and (max-width: 840px) {

    }

    .trois-colonnes {

        @media only screen and (max-width: 840px) {
            width: 100% !important;
            max-width: 100% !important;
            display: flex;
            flex-direction: row;
            max-width: 100%;
            overflow-x: scroll;
            padding-left: 20px;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            &:after {
                display: block;
                content: ' ';
                width: 1px; height: 1px;
                flex-shrink: 0;
            }

        }

        a {
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            @include generer-responsive('height', $grande-largeur, 160px, $largeur-mini, 100px);
            background: $blanc;
            transition: 0.25s background, 0.25s color;

            @media only screen and (max-width: 840px) {
                width: 85% !important;
                max-width: 320px;
                flex-shrink: 0;
            }

            svg {
                width: 48px;

                * {
                    transition: 0.25s fill;
                }
            }

            span {
                margin-top: 0;
            }
        }


        a:hover {
            color: $blanc;

            svg * { fill: $blanc; }
        }
    }
}

.bloc-chatbot {
    .iframe {
        .facade {
            position: relative;

            img {
                position: relative;
                z-index: 10;
                opacity: 1;
            }

            button {
                position: absolute;
                bottom: 12.5%; left: 50%;
                transform: translateX(-50%);
                z-index: 11;
                opacity: 1;
            }

            iframe {
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                object-fit: cover;
                z-index: 20;
                border: 1px solid $gris-clair;
                border-radius: 5px;
                overflow: hidden;
                opacity: 0;
            }

            &.loaded {
                height: 500px;
                transition: height 0.5s 0.25s;

                img, button {
                    opacity: 0;
                    transition: opacity 0.25s;
                }

                iframe {
                    opacity: 1;
                    transition: opacity 0.25s;
                }
            }
        }

        // height: 400px;
        // background: $gris-clair;
        //
        // iframe {
        //     width: 100%;
        //     height: 100%;
        //     // border: 1px solid $bleu-marine;
        // }
    }
}

.bloc-contact-cta {
    color: $blanc;

    &.avec-motif-geometrique {
        @media only screen and (min-width: 841px) {
            background-image: url('../img/motif-cta.svg');
            background-repeat: no-repeat;
            background-position: top right;
            background-size: auto 100%;
        }
    }

    .gros-bouton-blanc {
        border-color: $gris-fonce;

        &:hover {
            background: $gris-fonce;
        }
    }

    body:is(.main-bu-bleu,.main-bu-jaune,.main-bu-vert,.main-bu-rose) & {
        color: $bleu-marine;

        .gros-bouton-blanc {
            border-color: $blanc;

            &:hover {
                border-color: $gris-fonce;
            }
        }
    }
}

:is(.business-case,.formation) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    flex-shrink: 0;

    &.bu-bleu {color: $bu-bleu;}
    &.bu-gris {color: $bu-gris;}
    &.bu-rose {color: $bu-rose;}
    &.bu-vert {color: $bu-vert;}
    &.bu-jaune {color: $bu-jaune;}
    &.bu-marron {color: $bu-marron;}

    .group {
        background-color: $gris-clair;
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 0 50px 0 0;
        padding: 25px;
        transition: 0.25s background, 0.25s color;
        margin-top: -75px !important;
        width: 100%;

        .client {
            margin-bottom: 15px;
        }
        .decouvrir {
            margin-top: 30px;
        }
    }

    @media only screen and (min-width: 841px) {
        min-height: 500px;
    }

    & > * + * {
        margin-top: 0 !important;
    }

    & > span {
        display: inline-block;
        padding: 0 15px;

        &:first-child {
            padding-top: 15px;
        }

        &.client {
            padding-bottom: 10px;

            &.vide {
                @media only screen and (max-width: 840px) {
                    display: none;

                    & + [class*="titre-"] {
                        padding-top: 15px;
                    }
                }
            }
        }

        &.decouvrir {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    [class*="titre-"] {
        flex: 1;
    }

    img {
        width: 100%;
        aspect-ratio: 360 / 295;
        // height: 240px;
        object-fit: cover;
    }

    &:hover,
    &:active {
        .group {
            background: $bleu-marine;
        }
        background: $bleu-marine;
        color: $blanc;
    }

    &.bu-bleu:hover { .group {background: $bu-bleu;} }
    &.bu-gris:hover { .group {background: $bu-gris;} }
    &.bu-marron:hover { .group { background: $bu-marron;} }
    &.bu-jaune:hover { .group { background: $bu-jaune;} }
    &.bu-vert:hover { .group { background: $bu-vert;} }
    &.bu-rose:hover { .group { background: $bu-rose;} }

}

.bloc-business-cases-carrousel {

    .gros-bouton-blanc {
        padding-left: 20px;
        padding-right: 20px;
    }

    .scroll-horizontal{
        display: flex;
        gap: 30px;

        @media only screen and (max-width: 840px) {
            flex-direction: column;
            width: 380px;
            margin: 0 auto;
            max-width: calc(100% - 2 * $padding-lateral);
        }

        @media only screen and (min-width: 841px) {
            height: 480px;
            flex-direction: row;
            align-items: stretch;
            justify-content: flex-start;
            gap: 20px;
            padding-left: max(20px, calc(((100vw - $grande-largeur) / 2) - 20px));
        }

        & > * {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            flex-shrink: 0;

            @media only screen and (min-width: 841px) {
                width: 360px;
            }
        }
    }
}

.bloc-newsletter {
    .gform_wrapper {
        margin-top: 0;
    }

    .gform_validation_errors {
        color: $rouge-erreur;
        margin-bottom: 24px;
    }

    .gform_heading {
        display: none;
    }

    form {
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 30px;

        @media only screen and (min-width: 481px) {
            grid-template-columns: auto auto;
            align-items: start;
            column-gap: 30px;
        }

        .gfield_label {
            display: none;
        }

        .gfield_validation_message {
            color: $rouge-erreur;
            font: 15px/20px $montserrat;
            margin-top: 5px;
        }

        .ginput_container_email {
            input {
                width: 100%;
                border: 1px solid $blanc;
                border-radius: 15px;
                font: inherit;
                line-height: 20px;
                padding: 0 15px;
                height: 44px;
                background: transparent;
                appearance: none;
                color: $blanc;

                &::placeholder {
                    color: $blanc;
                    font-style: italic;
                }
            }
        }

        .gfield--type-checkbox {
            margin-top: 10px;
        }

        .gchoice {
            display: flex;
            align-items: flex-start;
        }

        .gfield-choice-input {
            flex-shrink: 0;
            display: inline-block;
            position: relative;
            border: 1px solid $blanc !important;
            background: transparent;
            width: 11px;
            height: 11px;
            // margin-right: 5px;
            margin: 4px 5px 0 0;
            border-radius: 0;

            &:checked:after {
                position: absolute;
                top: 50%; left: 50%;
                font-family: $fontello;
                font-size: 12px;
                content: '\e804';
                transform: translate(-50%, -50%);
            }

            & + label {
                font-size: 15px;
                line-height: 20px;
            }
        }

        .gform_footer {
            input {
                @extend .gros-bouton-blanc;
                border: 1px solid $blanc;
                height: 44px;

                @media only screen and (min-width: 481px) {
                    width: 100%;
                }

                &:hover {
                    cursor: pointer;
                    background: $gris-fonce;
                }
            }
        }
    }
}

.bloc-timeline {
    position: relative;
    padding: 0;

    .timeline {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 1px;
        height: 100%;
        background: rgba(0, 26, 60, 1);
        left: 0;

        @media only screen and (min-width: 841px) {
            left: 50%;
        }


        &:before { top: 0; }
        &:after { bottom: 0; }
    }

    .evenements {
        position: relative;
        z-index: 3;

        .evenement {
            position: relative;
            padding-bottom: 30px;
            padding-left: 30px;
            text-align: left;

            &::before {
                top: 0;
                position: absolute;
                z-index: 2;
                content: '';
                display: inline-block;
                height: 26px;
                width: 26px;
                border-radius: 10px;
                border: solid 1.5px $bleu-marine;
                background-color: #fff;
                transform: translateX(-50%);
                left: 0px;

                @media screen and (min-width: 841px) {
                    left: 50%;
                }
            }

            &.avec-image {
                @include generer-responsive('min-height', $grande-largeur, 390px, 840px, 250px);
            }

            img {
                object-fit: contain;
                aspect-ratio: 520 / 390;

                @media only screen and (max-width: 840px) {
                    margin-top: 24px;
                }

                @media only screen and (min-width: 841px) {
                    position: absolute;
                    top: 0;
                    width: 36%;
                }
            }

            &:nth-child(odd) {
                @media only screen and (min-width: 841px) {
                    padding-left: 55%;

                    img {
                        left: 0;
                    }

                    // &:before {
                    //     position: absolute;
                    //     top: 0; left: 0;
                    //     bottom: 0;
                    //     width: 45%;
                    //     content: ' ';
                    //     background: $gris-clair;
                    // }
                }
            }

            &:nth-child(even) {
                @media only screen and (min-width: 841px) {
                    text-align: right;
                    padding-right: 55%;

                    img {
                        right: 0;
                    }

                    // &:before {
                    //     position: absolute;
                    //     top: 0; right: 0;
                    //     bottom: 0;
                    //     width: 45%;
                    //     content: ' ';
                    //     background: $gris-clair;
                    // }
                }
            }

            .annee {
                display: inline-block;

                @media screen and (min-width: 841px) {
                    margin-top: -5px;
                }
            }
        }
    }
}


a.video {
    position: relative;
    display: block;

    &:after {
        position: absolute;
        top: 0; left: 0;
        z-index: 2;
        width: 100%; height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        content: '\e806';
        font-family: $fontello;
        opacity: 0.74;
        color: $blanc;
        transform: scale(1);
        transition: 0.25s transform, 0.25s opacity;
        // font-size: 132px;
        @include generer-responsive('font-size', $grande-largeur, 132px, $largeur-mini, 90px);
    }

    img {
        position: relative;
        z-index: 1;
    }

    &:hover {
        &:after {
            transform: scale(1.1);
            opacity: 0.95;
        }
    }
}

.bloc-texte-et-media {
    .media {
        aspect-ratio: 1/1;
        position: relative;
        z-index: 1;

        @media screen and (min-width: 840px) {
            max-width: 390px;
            min-width: 390px;
            max-height: 390px;
            min-height: 390px;
        }

        @media screen and (min-width: 1100px) {
            max-width: 440px;
            min-width: 440px;
            max-height: 440px;
            min-height: 440px;
        }

        body:is(.main-bu-bleu, .main-bu-jaune, .main-bu-gris, .main-bu-marron, .main-bu-rose, .main-bu-vert) & {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                border-radius: 50%;
                z-index: -1;
            }
            img {
                opacity: .8;
            }
        }

        &::after {
            content: '';
            width: 390px;
            height: 390px;
            border-radius: 50%;
            border: 2px solid $bleu-marine;
            position: absolute;
            z-index: -2;
            left: calc(100% - 110px);
            top: 0;

            @media screen and (min-width: 840px) {
                top: -60px;
            }
        }

        @at-root .texte-a-droite .media::after {
            right: calc(100% - 110px);
            left: initial;
        }

        .slick-slide {
            div {
                height: 440px;
                width: 440px;
            }
        }
        img, .video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &.texte-a-gauche,
    .texte-a-gauche {
        @media only screen and (min-width: 841px) {
            .media { order: 2; }
            .texte { order: 1; }
        }
    }

    @media only screen and (max-width: 840px) {
        .media { order: 2; }
        .texte { order: 1; }
    }
}

.bloc-valeurs {
    padding: 37px 0 92px 0;
    .valeur {
        .visuels {
            position: relative;
            background: $bleu-marine;
            aspect-ratio: 1 / 1;

            .picto {
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                z-index: 2;
                background: rgba(0, 26, 60, 0.9);
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    transform: translateY(-32px);
                    width: 174px;
                }
            }

            .fond {
                position: absolute;
                top: 0; left: 0;
                width: 100%; height: 100%;
                z-index: 1;

                img {
                    object-fit: cover;
                }
            }
        }

        .wysiwyg {
            border-radius: 0 50px 0 0;
            position: relative;
            z-index: 2;
            background-color: #fff;
            padding: 50px 20px 30px;
            margin-top: -75px;
        }
    }
}

.bloc-trombinoscope {
    .profil {
        transition: .3s;

        img {
            aspect-ratio: 1 / 1;
            object-fit: cover;
            width: 100%;
        }

        .wysiwyg {
            border-radius: 0 50px 0 0;
            position: relative;
            z-index: 2;
            background-color: #F9F8F8;
            padding: 50px 20px 30px;
            margin-top: -75px;
            transition: .3s;
        }

        .picto {
            font-size: 32px;
            margin-left: -6px;
        }

        &:hover {
            color: $blanc;
            background: $bleu-marine !important;
            transition: 0.25s color, 0.25s background;

            .wysiwyg {
                background: $bleu-marine;
            }
        }

        &.bu-bleu:hover { background: $bu-bleu; }
        &.bu-gris:hover { background: $bu-gris; }
        &.bu-marron:hover { background: $bu-marron; }
        &.bu-jaune:hover { background: $bu-jaune; }
        &.bu-vert:hover { background: $bu-vert; }
        &.bu-rose:hover { background: $bu-rose; }

    }
}

.bloc-recrutement-cta {
    .contenu-large {
        .media {
            img {
                display: block;
                border-radius: 0 50px 0 0;
            }
        }

        .texte {
            display: flex;
            flex-direction: column;
        }

        .wysiwyg {

        }

        .cta {
            .bouton-bleu-marine {
                box-shadow: 0px 4px 4px 0px #00000026;
            }
        }
    }
}

:is(.bloc-telechargements, .bloc-accordeons-version-boutons, .bloc-accordeons-version-fleches) {
    & > * + * {
        border-top: 1px solid $bleu-marine;
    }
}

.accordeon {
    @media only screen and (min-width: 1250px) {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 26px;
            width: 26px;
            border-radius: 10px;
            border: solid 1.5px $ligne-bleu;
            background-color: #fff;
            left: -42px;
            top: 69px;

            body:is(.main-bu-gris) & {border: solid 1.5px $bu-gris;}
            body:is(.main-bu-bleu) & {border: solid 1.5px $bu-bleu;}
            body:is(.main-bu-jaune) & {border: solid 1.5px $bu-jaune;}
            body:is(.main-bu-marron) & {border: solid 1.5px $bu-marron;}
            body:is(.main-bu-rose) & {border: solid 1.5px $bu-rose;}
            body:is(.main-bu-vert) & {border: solid 1.5px $bu-vert;}
        }
        &::before {
            content: '';
            top: 83px;
            position: absolute;
            width: calc(((100vw - ($grande-largeur + 2 * $padding-lateral)) / 2) - 20px);
            height: 1.5px;
            background-color: $ligne-bleu;
            left: calc(((100vw - ($grande-largeur + 2 * $padding-lateral)) / 2) * -1);

            body:is(.main-bu-gris) & {background-color:$bu-gris;}
            body:is(.main-bu-bleu) & {background-color:$bu-bleu;}
            body:is(.main-bu-jaune) & {background-color:$bu-jaune;}
            body:is(.main-bu-marron) & {background-color:$bu-marron;}
            body:is(.main-bu-rose) & {background-color:$bu-rose;}
            body:is(.main-bu-vert) & {background-color:$bu-vert;}
        }
    }
}

.bloc-accordeons-version-boutons {
    .accordeon {


        @media only screen and (min-width: 841px) {
            .flex-ligne {
                align-items: center;
            }
        }
        @media only screen and (max-width: 840px) {
            .flex-ligne {
                position: relative;
                @include generer-responsive('padding-right', $grande-largeur, 60px, $largeur-mini, 40px);

                &:after {
                    position: absolute;
                    top: 50%; right: 0;
                    font-family: $fontello;
                    font-size: 0.9em;
                    content: '\e813';
                    font-weight: normal;
                    line-height: inherit;
                    transform: translateY(-50%) scaleY(1);
                    transition: transform 0.25s;
                }

                &:hover {
                    cursor: pointer;
                }
            }

            &.ouvrir .flex-ligne:after {
                transform: translateY(-50%) scaleY(-1);
            }
        }

        .texte {
            display: grid;
            transition: 0.25s grid-template-rows ease;
            // grid-template-rows: 1fr;
            grid-template-rows: 0fr;

            // @media only screen and (min-width: 841px) {
            //     grid-template-rows: 0fr;
            // }

            div {
                overflow: hidden;
            }
        }

        &.ouvrir .texte {
            grid-template-rows: 1fr;
        }

        .gros-bouton-blanc {
            padding-left: 45px;
            padding-right: 45px;

            &.mobile {
                display: inline-block;
                @media only screen and (min-width: 841px) {
                    display: none;
                }
            }
            &.bureau {
                display: none;
                @media only screen and (min-width: 841px) {
                    display: inline-block;
                }
            }
        }
    }
}

.bloc-reassurance {
    ul {
        list-style: none;
        li  {
            padding-left: 44px;
            padding-bottom: 10px;

            &::before {
                content: "";
                background-size: contain;
                background-repeat: no-repeat;
                width: 35px;
                height: 19px;
                background-image: url('../img/list-arrow.svg');

                @media screen and (min-width: 840px) {
                    top: 3px;
                }
            }
        }
    }
}

.bloc-accordeons-version-fleches {
    .accordeon {
        button {
            width: 100%;
            position: relative;
            text-align: left;
            @include generer-responsive('padding-right', $grande-largeur, 60px, $largeur-mini, 40px);

            &:after {
                position: absolute;
                top: 50%; right: 0;
                font-family: $fontello;
                font-size: 0.9em;
                content: '\e813';
                font-weight: normal;
                line-height: inherit;
                transform: translateY(-50%) scaleY(1);
                transition: transform 0.25s;
            }

            &[aria-expanded="true"]:after {
                transform: translateY(-50%) scaleY(-1);
            }
        }

        .texte {
            display: grid;
            grid-template-rows: 0fr;
            transition: 0.25s grid-template-rows ease, 0.25s padding;

            div {
                overflow: hidden;
            }
        }

        button[aria-expanded="true"] + .texte {
            grid-template-rows: 1fr;
            padding-top: 30px;
        }
    }
}

.bloc-clients {
    .logos {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 15px;
        column-gap: 30px;

        & > div {
            opacity: 0.5;
            transition: 0.25s opacity;

            img {
                object-fit: contain;
                filter: grayscale(1);
            }

            &:hover {
                opacity: 1;

                img {
                    filter: grayscale(0);
                }
            }

            &.carre {
                img {
                    @include generer-responsive('height', $grande-largeur, 100px, $largeur-mini, 45px);
                    @include generer-responsive('width', $grande-largeur, 100px, $largeur-mini, 45px);
                }
            }

            &.paysage {
                img {
                    height: auto;
                    @include generer-responsive('width', $grande-largeur, 160px, $largeur-mini, 70px);
                }
            }

            &.portrait {
                img {
                    width: auto;
                    @include generer-responsive('height', $grande-largeur, 100px, $largeur-mini, 45px);
                    @include generer-responsive('max-width', $grande-largeur, 100px, $largeur-mini, 45px);
                }
            }
        }
    }
}

.bloc-temoignages {
    position: relative;
    @include generer-responsive('font-size', $grande-largeur, 24px, $largeur-mini, 18px);
    line-height: 1.3em;

    & > div {
        @media only screen and (min-width: 1301px) {
            position: relative;
        }
    }

    &.avec-bordure:before {
        position: absolute;
        top: 0; left: 50%;
        width: $grande-largeur + 40px;
        max-width: calc(100% - 40px);
        height: 0.5px;
        background: $bleu-marine;
        content: ' ';
        transform: translateX(-50%);
    }

    .temoignages {
        @media only screen and (min-width: 481px) and (max-width: 1250px) {
            padding: 0 20px;
        }
    }

    .temoignage {
        position: relative;

        @media only screen and (max-width: 480px) {
            padding-bottom: 70px;
        }

        .flex {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media only screen and (min-width: 481px) {
                flex-direction: row;
                align-items: center;
            }
        }

        &:before {
            font-family: $fontello;
            content: '\e80c';
            position: absolute;
            top: 0; left: 0;
            transform: translateY(40%);
            @include generer-responsive('font-size', $grande-largeur, 54px, $largeur-mini, 38px);
        }

        .textes {
            padding: 70px 0 0 0;

            @media only screen and (min-width: 481px) {
                padding: 70px 0;
            }
        }

        picture, img {
            flex-shrink: 0;
        }

        img {
            // flex-shrink: 0;
            @include generer-responsive('width', $grande-largeur, 280px, $largeur-mini, 120px);
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }

        .auteur {
            font-weight: 700;
        }
    }

    :is(.precedent,.suivant) {
        position: absolute;
        top: 50%;
        width: 40px;
        height: 100px;
        text-align: center;

        @media only screen and (max-width: 480px) {
            display: none;
        }
        @media only screen and (min-width: 1301px) {
            width: 100px;
        }
    }

    .precedent {
        left: 0;
        @media only screen and (min-width: 1301px) {
            transform: translate(-100%,-50%);
        }
    }
    .suivant {
        right: 0;
        @media only screen and (min-width: 1301px) {
            transform: translate(100%,-50%);
        }
    }

    .slick-slide {
        margin: 0 24px;
    }
    .slick-list {
        margin: 0 -24px;
    }
    .slick-dots li button:before { color: $bu-gris; }
    .slick-dots li.slick-active button:before { color: $bleu-marine; }
}

.pagination {
    & > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        flex-wrap: wrap;
        // padding-top: 16px;
        @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
    }

    :is(a, span) {
        display: inline-block;
        text-align: center;
        padding: 5px;
    }

    :is(a:hover, span.en-cours) {
        font-weight: 600;
    }
}

.bloc-contact-bureaux {
    .bureaux {
        position: relative;
        padding-top: 40px;

        &:before {
            position: absolute;
            top: 0; left: 50%;
            content: '\e805';
            display: inline-block;
            font-size: 20px;
            width: 19px;
            transform: translateX(-50%);
            color: $bleu-marine;
            font-family: $fontello;
            background: $blanc;
            line-height: 15px;
        }
    }
}

.bloc-formulaire {

    [class*="titre-"] ~ .gform_wrapper {
        margin-top: 40px;
    }

    .gform_confirmation_message {
        background: $bleu-marine;
        color: $blanc;
        padding: 40px 20px;
        text-align: center;
        @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
    }

    .gform_validation_errors {
        color: $rouge-erreur;
        margin: 60px 0 40px;
        text-align: left;
        outline: none;

        & > * + * {
            margin-top: 12px;
        }

        ol {
            display: block;
            list-style: none;

            li {
                position: relative;
                padding: 0 0 0 32px;
                text-align: left;

                &:last-child {
                    padding-bottom: 0;
                }

                &:before {
                    position: absolute;
                    top: 0; left: 0;
                    // font-weight: bold;
                    content: '•';
                    width: 32px;
                    font-size: 1.2em;
                    text-align: center;
                }
            }
        }
    }

    .gform_heading {
        display: none;
    }

    .gform_body {
        & > div {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 24px;

            @media only screen and (min-width: 841px) {
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
                row-gap: 40px;

                & > .spans-2 {
                    grid-column: 1 / span 2;
                }
            }
        }
    }

    form {
        .gfield_label {
            display: none;
        }

        .gfield_validation_message {
            color: $rouge-erreur;
            font: 15px/20px $montserrat;
            padding: 8px 0;
            text-align: left;
        }

        input[type=text],
        input[type=password],
        input[type=email],
        input[type=number],
        input[type=date],
        input[type=url],
        input[type=tel],
        textarea,
        select {
            width: 100%;
            font: inherit;
            line-height: 20px;
            padding: 12px 15px;
            height: 44px;
            background: $gris-clair;
            border: 1px solid $gris-clair;
            border-radius: 15px;
            appearance: none;
            outline: none;
            color: inherit;
            font-weight: 600;

            &:focus {
                border-color: $gris-fonce;
            }
        }

        input::placeholder,
        textarea::placeholder {
            color: $bleu-marine;
            font-weight: 400;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }

        textarea {
            height: auto;
        }

        .gfield--type-checkbox {
            margin-top: 10px;
        }

        .gchoice {
            display: flex;
            align-items: center;
        }

        .gfield-choice-input {
            flex-shrink: 0;
            display: inline-block;
            position: relative;
            border: 1px solid $bleu-marine;
            border-radius: 0;
            background: transparent;
            width: 21px;
            height: 21px;
            margin-right: 15px;

            &:checked:after {
                position: absolute;
                top: 50%; left: 50%;
                font-family: $fontello;
                font-size: 18px;
                content: '\e804';
                transform: translate(-50%, -50%);
            }

            & + label {
                font: italic 20px/1.2em $montserrat;
                @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
                text-align: left;
            }
        }

        .gfield--input-type-fileupload {
            position: relative;
            text-align: left;
            padding-left: 30px;
            max-width: 100%;
            overflow: hidden;

            &:before {
                position: absolute;
                top: 5px; left: 0;
                content: '\e805';
                display: inline-block;
                font-size: 20px;
                width: 19px;
                // transform: translateX(-50%);
                color: $bleu-marine;
                font-family: $fontello;
                background: $blanc;
                line-height: 15px;
            }

            .gfield_label {
                display: inline-block;
                margin-bottom: 10px;
            }

            .gfield_required {
                display: none;
            }

            .gform_fileupload_rules {
                display: block;
                margin-top: 10px;
                font: italic 15px/20px $montserrat;
            }
        }

        .gform_footer {
            margin-top: 45px;
            text-align: left;

            input {
                @extend .bouton-bleu-marine;
                font: 600 20px/1em $montserrat;
                @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
                width: 188px;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.bloc-tarifs {
    .onglets {
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        align-items: stretch;
        @include generer-responsive('gap', $grande-largeur, 40px, $largeur-mini, 12px);

        .onglet {
            border-radius: 15px;
        }
    }

    button {
        display: inline-block;
        border: 1px solid $bleu-marine;
        background: $blanc;
        color: $bleu-marine;
        font-size: 14px;
        transition: .25s color, .25s background;
        padding: 8px 20px;
        @media only screen and (min-width: 841px) {
            padding: 8px 45px;
        }

        &[aria-expanded=true] {
            background: $bleu-marine;
            color: $blanc;
        }
    }
}

.animate-height {
    position: relative;
    height: auto;
    transition: height 0.25s;
}

.section-onglet {
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s 0.25s;

    &.ferme {
        display: none;
    }

    &.clone {
        position: absolute;
        top: 0; left: 0;
        width: 100%;
    }

    &.ouvert {
        opacity: 1;
        visibility: visible;
        transition: visibility 0s, opacity 0.25s 0.25s;
    }
}

.formules {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width: 841px) {
        flex-direction: row;
        align-items: stretch;
    }

    .formule {
        padding-left: 20px;
        padding-right: 20px;
        width: 320px;
        max-width: 100%;
        border-radius: 25px;

        .tarif {
            font-weight: 400;
        }

        i {
            font-size: 16px;
        }
    }
}

#filtres {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 30px;

    & > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 30px;
    }

    .enveloppe-select {
        width: 200px;
        position: relative;
        background: $blanc;
        border: 1px solid $bleu-marine;
        z-index: 4;

        &:after {
            position: absolute;
            top: 11px; right: 8px;
            z-index: 5;
            width: 20px;
            font: 20px/1em $fontello;
            color: inherit;
            content: '\e800';
        }

        select {
            position: relative;
            z-index: 6;
            background: transparent;
            padding: 5px 30px 5px 15px;
            border: none;
            appearance: none;
            width: 100%;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 600;
            color: $bleu-marine;
            @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);

            &::-ms-expand {
                display: none;
            }

            &:hover {
                cursor: pointer;
            }

            &:focus {
                background: transparent;
            }
        }
    }
}

.bloc-linkedin {
    iframe {
        width: 100%;
        height: 360px;
        //
        // @media only screen and (min-width: 481px) {
        //     height: 360px;
        // }
    }
}

#footer {
    a, span.cookies {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .sections {
        display: flex;
        flex-direction: row;
        gap: 30px;
        flex-wrap: wrap;

        .section {
            width: calc(50% - 15px);

            @media only screen and (min-width: 841px) {
                $nb_sections: 6;
                width: calc(($grande-largeur - 30px * ($nb_sections - 1)) / $nb_sections);
            }

            p.label {
                & > * {
                    font-weight: 600;
                    line-height: 1.3em;
                    @include generer-responsive('font-size', $grande-largeur, 20px, $largeur-mini, 16px);
                }
            }

            .liens {
                display: flex;
                flex-direction: column;
                font-size: 12px;
                line-height: 1.4em;
            }
        }
    }

    .liens-legaux {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        font-size: 12px;

        & > * {
            color: $blanc;
        }
    }
}
