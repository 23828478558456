@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

.montserrat-300 {
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 300;
}

.montserrat-400 {
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
}

.montserrat-600 {
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 600;
}

@font-face {
    font-family: 'fontello';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('../fonts/fontello.eot?42877266#iefix') format('embedded-opentype'),
         url('../fonts/fontello.woff2?42877266') format('woff2'),
         url('../fonts/fontello.woff?42877266') format('woff'),
         url('../fonts/fontello.ttf?42877266') format('truetype'),
         url('../fonts/fontello.svg?42877266#fontello') format('svg');
}

[class^="icon-"]::before {
    display: inline-block;
    width: 1em;
    margin-right: .2em;
    line-height: 1em;
    margin-left: .2em;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu-deroulant:before { content: '\e800'; } /* '' */
.icon-fleche-droite:before { content: '\e801'; } /* '' */
.icon-left:before { content: '\e802'; } /* '' */
.icon-right:before { content: '\e803'; } /* '' */
.icon-ok:before { content: '\e804'; } /* '' */
.icon-plus:before { content: '\e805'; } /* '' */
.icon-play:before { content: '\e806'; } /* '' */
.icon-participants:before { content: '\e807'; } /* '' */
.icon-chevron-droite:before { content: '\e808'; } /* '' */
.icon-chargement:before { content: '\e809'; } /* '' */
.icon-fermer:before { content: '\e80a'; } /* '' */
.icon-telecharger:before { content: '\e80b'; } /* '' */
.icon-quotes:before { content: '\e80c'; } /* '' */
.icon-chevron-gauche:before { content: '\e80d'; } /* '' */
.icon-localisation:before { content: '\e80f'; } /* '' */
.icon-type-formation:before { content: '\e810'; } /* '' */
.icon-chevron-haut:before { content: '\e813'; } /* '' */
.icon-linkedin-circled:before { content: '\f319'; } /* '' */
