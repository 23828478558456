body.compensate-for-scrollbar {
    overflow: hidden;
}

.fancybox-active {
    height: auto;
}

.fancybox-is-hidden {
    left: -9999px;
    margin: 0;
    position: absolute !important;
    top: -9999px;
    visibility: hidden;
}

.fancybox-container {
    -webkit-backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992;
}

.fancybox-container * {
    box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}

.fancybox-bg {
    //background: rgba(255, 255, 255, 1);
    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(.47, 0, .74, .71);
}

.fancybox-is-open .fancybox-bg {
    opacity: 1;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity .25s ease, visibility 0s ease .25s;
    visibility: hidden;
    z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    transition: opacity .25s ease 0s, visibility 0s ease 0s;
    visibility: visible;
}

.fancybox-infobar {
    -webkit-font-smoothing: subpixel-antialiased;
    left: 0;
//	mix-blend-mode: difference;
    pointer-events: none;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fancybox-toolbar {
    right: 0;
    top: 0;
//	top: 25px; right: 20px;
    
}

.fancybox-stage {
    direction: ltr;
    overflow: visible;
    transform: translateZ(0);
    z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
    overflow: hidden;
}

.fancybox-slide {
    -webkit-backface-visibility: hidden; /* Using without prefix would break IE11 */
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    /*padding: 44px;*/
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform, opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994;
}

.fancybox-slide::before {
    content: '';
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
    display: block;
}

.fancybox-slide--image {
    overflow: hidden;
    //padding: 44px 0;
}

.fancybox-slide--image::before {
    display: none;
}

.fancybox-slide--html {
    padding: 5px;
}

.fancybox-content {
    //background: #fff;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    //padding: 44px;
    position: relative;
    text-align: left;
    vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
    animation-timing-function: cubic-bezier(.5, 0, .14, 1);
    -webkit-backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    -ms-transform-origin: top left;
    transform-origin: top left;
    transition-property: transform, opacity;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
    cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
    cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
    cursor: -webkit-grab;
    cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
    cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
}

.fancybox-spaceball {
    z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%;
    //background: #fff;
    max-width: 95%;
    max-height: 90%;
    margin: 0;
}

.fancybox-video,
.fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

/* Fix iOS */
.fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0;
}

.fancybox-error {
    //background: #fff;
    cursor: default;
    max-width: 400px;
    padding: 40px;
    width: 100%;
}

.fancybox-error p {
    color: #444;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}

/* Buttons */

.fancybox-button {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-flex;
    //height: 50px;
    margin: 0;
    //padding: 10px;
    position: relative;
    transition: color .2s;
    justify-content: center;
    align-items: center;
    visibility: inherit;
    //width: 50px;
}

/* Fix IE11 */
.fancybox-button div {
    height: 100%;
}

.fancybox-button svg {
    display: block;
    overflow: visible;
    position: relative;
    //width: 22px;
    height: auto;
    //max-height: 34px;
    
//	@media only screen and (max-width: 520px) {
//		width: 20px;
//	}
    
//	* {
//		fill: @gris;
//
//		&:hover {
//			fill: @noir;
//		}
//	}
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
    display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
    display: none;
}

.fancybox-progress {
    //background: #ff5268;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -ms-transform-origin: 0;
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    //color: #ccc;
    cursor: pointer;
    opacity: .8;
    padding: 8px;
    position: absolute;
    right: -12px;
    top: -44px;
    z-index: 401;
}

.fancybox-close-small:hover {
    //color: #fff;
    opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 10px;
    right: 0;
    top: 0;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
    opacity: 0;
    position: absolute;
    top: calc(50% - 50px);
    height: 100px; width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fancybox-navigation .fancybox-button div {
    padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
}

.fancybox-navigation .fancybox-button--arrow_right {
    right: 0;
    right: env(safe-area-inset-right);
}

/* Caption */

.fancybox-caption {
    bottom: 0;
    left: 0;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996;
    padding: 20px;

    span {
        display: block;
    }
    
    span.description {
        padding-top: 5px;
        font-size: 0.9em;
    }
}

.fancybox-caption--separate {
    margin-top: -50px;
}

.fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
    color: #ccc;
    text-decoration: none;
}

.fancybox-caption a:hover {
    color: #fff;
    text-decoration: underline;
}

/* Loading indicator */

.fancybox-loading {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    animation: fancybox-rotate 1s linear infinite;
    z-index: 99999;
}

@keyframes fancybox-rotate {
    100% {
        transform: rotate(360deg);
    }
}

/* Transition effects */

.fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, .25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
    opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
    .fancybox-slide {
        padding-left: 6px;
        padding-right: 6px;
    }

    .fancybox-slide--image {
        padding: 6px 0;
    }

    .fancybox-close-small {
        right: -6px;
    }

    .fancybox-slide--image .fancybox-close-small {
        background: #4e4e4e;
        color: #f2f4f6;
        height: 36px;
        opacity: 1;
        padding: 6px;
        right: 0;
        top: 0;
        width: 36px;
    }

    .fancybox-caption {
        padding-left: 12px;
        padding-right: 12px;
    }

    @supports (padding: max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    }
}


$fancybox-hauteur: 50px;
$fancybox-couleur: #000;
$fancybox-couleur-hover: #000;
$fancybox-fond: #fff;

.fancybox-bg {
    background: $fancybox-fond;
}

.fancybox-infobar {
    color: $fancybox-couleur;
    font-size: 16px;
    height: $fancybox-hauteur;
    line-height: $fancybox-hauteur;
    min-width: $fancybox-hauteur;
    padding: 0 10px;
}

.fancybox-slide {
    @media only screen and (min-width: 841px) {
        padding: $fancybox-hauteur;
    }
}

.fancybox-slide--image {
    padding: $fancybox-hauteur 0;
}

.fancybox-content {
    background: $fancybox-fond;
    padding: $fancybox-hauteur;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--iframe .fancybox-content,
.fancybox-error {
    background: $fancybox-fond;
}

.fancybox-button {
    height: $fancybox-hauteur;
    width: $fancybox-hauteur;
}

.fancybox-button svg,
.fancybox-loading svg {
    width: 22px;
    max-height: 34px;
    
    @media only screen and (max-width: 520px) {
        width: 20px;
    }
    
    * {
        fill: $fancybox-couleur;
    
        &:hover {
            fill: $fancybox-couleur-hover;
        }
    }
}

.fancybox-caption {
    color: $fancybox-couleur;
    font: 18px/1.3em $montserrat;
    padding: 15px;
    background: fade($fancybox-fond, 80%);
}

.fancybox-caption--separate {
    margin-top: -$fancybox-hauteur;
}

