$montserrat: 'Montserrat', sans-serif;
$fontello: 'fontello';

//$bleu-marine: #001A3C;
$bleu-marine: #242F62;
$blanc: #FFFFFF;
$gris-clair: #F9F8F8;
$gris-fonce: #3A3C3E;

$fond-blanc: $blanc;
$fond-blanc-transparent: rgba(255, 255, 255, 95%);

$ligne-bleu: #63C3D1;

$bu-gris: #8E9ED1;
$bu-bleu: #63C3D1;
$bu-marron: #F3953F;
$bu-jaune: #FBBB21;
$bu-vert: #6AB67F;
$bu-rose: #EA5178;

$rouge-erreur: rgb(255, 93, 93);

$padding-lateral: var(--padding-lateral);
$espace: 30px;

$largeur-mini: 480px;
$moyenne-largeur: 900px;
$grande-largeur: 1120px;

$breakpoint-menu: 1100px;
$decalage-bas-menu: 14px;
