:is(#header, #main, .contenu-pleine-largeur, #footer) {

    [class*="contenu-"]:not(.contenu-pleine-largeur) {
        margin-left: auto;
        margin-right: auto;
        width: $largeur-mini;
        max-width: calc(100% - 2 * $padding-lateral);

        @media only screen and (min-width: 841px) {
            &.contenu-moyen {
                width: calc($moyenne-largeur + 2 * $padding-lateral);
            }
            
            &.contenu-large {
                width: calc($grande-largeur + 2 * $padding-lateral);
            }
        }
    }

    &:not(#header) > [class*="contenu-"]:not(.contenu-pleine-largeur, [class*="margin-"], :first-child),
    & > [class*="contenu-"]:not(.contenu-pleine-largeur) + .contenu-pleine-largeur:not([class*="margin-"]) {
        @include generer-responsive('margin-top', $grande-largeur, 80px, $largeur-mini, 40px);
    }
}

#main > [class*="contenu-"]:last-child:not(.contenu-pleine-largeur:not(.bloc-business-cases-carrousel)) {
    @include generer-responsive('margin-bottom', $grande-largeur, 80px, $largeur-mini, 40px);
}

.contenu-pleine-largeur.fond-gris-clair + .contenu-pleine-largeur.fond-gris-clair {
    // padding-top: 0;
}

.margin-80 { @include generer-responsive('margin-top', $grande-largeur, 80px, $largeur-mini, 40px); }
.margin-70 { @include generer-responsive('margin-top', $grande-largeur, 70px, $largeur-mini, 35px); }
.margin-40 { @include generer-responsive('margin-top', $grande-largeur, 40px, $largeur-mini, 30px); }
.margin-30 { margin-top: 30px; }
.margin-24 { margin-top: 24px; }
.margin-10 { margin-top: 10px; }
.margin-0 { margin-top: 0; }


.padding-top-120 { @include generer-responsive('padding-top', $grande-largeur, 120px, $largeur-mini, 60px); }
.padding-bottom-120 { @include generer-responsive('padding-bottom', $grande-largeur, 120px, $largeur-mini, 60px); }

.padding-top-80 { @include generer-responsive('padding-top', $grande-largeur, 80px, $largeur-mini, 40px); }
.padding-bottom-80 { @include generer-responsive('padding-bottom', $grande-largeur, 80px, $largeur-mini, 40px); }

.padding-top-70 { @include generer-responsive('padding-top', $grande-largeur, 70px, $largeur-mini, 35px); }
.padding-bottom-70 { @include generer-responsive('padding-bottom', $grande-largeur, 70px, $largeur-mini, 35px); }

.padding-top-60 { @include generer-responsive('padding-top', $grande-largeur, 60px, $largeur-mini, 30px); }
.padding-bottom-60 { @include generer-responsive('padding-bottom', $grande-largeur, 60px, $largeur-mini, 30px); }

.padding-top-40 { @include generer-responsive('padding-top', $grande-largeur, 40px, $largeur-mini, 30px); }
.padding-bottom-40 { @include generer-responsive('padding-bottom', $grande-largeur, 40px, $largeur-mini, 30px); }

.padding-top-30 { padding-top: 30px; }
.padding-bottom-30 { padding-bottom: 30px; }

.padding-desktop-bottom-0 { @media screen and (min-width: 841px) {padding-bottom: 0 !important;} }


.gap-20-40 {
    column-gap: 20px;
    @include generer-responsive('row-gap', $grande-largeur, 40px, $largeur-mini, 24px);
}
.gap-40 { @include generer-responsive('gap', $grande-largeur, 40px, $largeur-mini, 24px); }
.gap-60 { @include generer-responsive('gap', $grande-largeur, 60px, $largeur-mini, 24px); }
.gap-80 { @include generer-responsive('gap', $grande-largeur, 80px, 840px, 30px); }

.alignement-vertical-haut { align-items: start; }
.alignement-vertical-centre { align-items: center; }
.alignement-vertical-bas { align-items: end; }
.alignement-stretch { align-items: stretch; }


:is(.flex-ligne, [class*="-colonnes"]) {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    & > * {
        width: 100%;

        & > * + *:not([class*="margin-"]) {
            margin-top: 24px;
        }

        & > :first-child {
            margin-top: 0 !important;
        }
    }
}

.flex-ligne {
    @media only screen and (min-width: 841px) {
        flex-direction: row;
        justify-content: space-between;

        & > * {
            width: auto;
        }
    }
}

.deux-colonnes {
    @media only screen and (min-width: 841px) {
        flex-direction: row;
        justify-content: space-between;
    }

    & > * {
        @media only screen and (min-width: 841px) {
            width: (calc(520px / $grande-largeur) * 100%);
        }
    }

    &.media-a-gauche {
        .media {
            order: 1;
        }
        .texte {
            order: 2;
        }
    }
    &.media-a-droite {
        .media {
            order: 2;
        }
        .texte {
            order: 1;
        }
    }
}

.trois-colonnes {
    & > * {
        @media only screen and (max-width: 840px) {
            width: $largeur-mini;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media only screen and (min-width: 841px) {
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (min-width: 1001px) {
        grid-template-columns: repeat(3, 1fr);
    }

    .pagination {
        @media only screen and (min-width: 841px) {
            grid-column: 1 / span 2;
        }
        @media only screen and (min-width: 1001px) {
            grid-column: 1 / span 3;
        }
    }
}

