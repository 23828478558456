& > :first-child {
    margin-top: 0 !important;
}

& > * + :not([class*="margin-"]) {
    margin-top: 24px;
}

a:not([class*="bouton-"]) {
    text-decoration: underline;
    
    &:hover,
    &:focus {
        text-decoration: none;
    }
}

:is(.texte-gras, strong) {
    font-weight: 600;
}

:is(ul, ol) {
    display: block;
    list-style: none;
    padding-left: 0;
    
    li {
        position: relative;
        padding: 0 0 0 24px;
        text-align: left;
        
        &:last-child {
            padding-bottom: 0;
        }
        
        &:before {
            position: absolute;
            top: 0; left: 0;
            // font-weight: bold;
            content: '•';
            width: 24px;
            font-size: 1.2em;
            text-align: center;
        }
    }
}

ol {
    li {
        counter-increment: item;
        
        &:before {
            content: counter(item)'.';
        }
    }
}

.alignleft {
    display: inline;
    float: left;
    margin-right: 30px;
    margin-bottom: 15px;
    max-width: 50%;
}

.alignright {
    display: inline;
    float: right;
    margin-left: 30px;
    margin-bottom: 15px;
    max-width: 50%;
}

.aligncenter {
    clear: both;
    display: block;
    margin: 2em auto;
}

