@use 'sass:math';

@mixin generer-line-clamp($nb_lignes) {
    display: -webkit-box;
    line-clamp: $nb_lignes;
    -webkit-line-clamp: $nb_lignes;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin generer-bouton() {
    display: inline-block;
    font: 500 14px/1.2em $montserrat;
    padding: 10px 15px;
    appearance: none;
    text-align: center;
    border-radius: 15px;
}

@function retirer-unite($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, $number * 0 + 1);
    }

    @return $number;
}

@mixin generer-visually-hidden() {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    &.skip:focus {
        height: auto;
        width: auto;
        clip: unset;
    }
}

@mixin generer-animation-rotation() {
	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
}

@mixin appliquer-animation-rotation($duree: 1s) {
	animation: rotation $duree linear infinite;
}

@mixin generer-responsive($attribut, $breakpoint_max, $taille_max, $breakpoint_min, $taille_min) {

    // #{$attribut}: $taille_min;
    //
	// @media screen and (min-width: $breakpoint_min) {
	// 	#{$attribut}: calc($taille_min + (#{retirer-unite($taille_max)} - #{retirer-unite($taille_min)}) * calc((100vw - $breakpoint_min) / (#{retirer-unite($breakpoint_max)} - #{retirer-unite($breakpoint_min)})));
	// }
    //
    // @media screen and (min-width: $breakpoint_max) { #{$attribut}: $taille_max; }

    #{$attribut}: clamp($taille_min, calc($taille_min + (#{retirer-unite($taille_max)} - #{retirer-unite($taille_min)}) * calc((100vw - $breakpoint_min) / (#{retirer-unite($breakpoint_max)} - #{retirer-unite($breakpoint_min)}))), $taille_max);

}

