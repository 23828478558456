[class*="titre-"] {
    line-height: 121%;
    font-family: $montserrat;

    &:not(.font-weight-normal) {
        font-weight: 600;
    }

    br:not(.forcer) {
        display: none;

        @media only screen and (min-width: 841px) {
            display: block;
        }
    }
}

.titre-90 { @include generer-responsive('font-size', $grande-largeur, 90px, $largeur-mini, 40px); line-height: 105%; }
.titre-48 { @include generer-responsive('font-size', $grande-largeur, 48px, $largeur-mini, 24px); }
.titre-38 { @include generer-responsive('font-size', $grande-largeur, 38px, $largeur-mini, 20px); }
.titre-28 { @include generer-responsive('font-size', $grande-largeur, 28px, $largeur-mini, 18px); }
.titre-26 { @include generer-responsive('font-size', $grande-largeur, 26px, $largeur-mini, 18px); font-weight: 700 !important; }
